/* eslint-disable */
import React from 'react'

import WomanSmiling from '../../images/people/woman-smiling.png'
import WomanSmilingMobile from '../../images/people/woman-smiling-mobile.png'
import CoupleOnLaptop from '../../images/people/couple-on-laptop.jpg'
import CoupleOnLaptopMobile from '../../images/people/couple-on-laptop-mobile.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import NarrowBanner from '../../components/NarrowBanner'
import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  Billboard,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Explore Medicare D-SNPs | ClearlinkInsurance.com',
          description:
            'Dual Eligible Special Needs Plans (D-SNPs) provide additional coverage for those with both Medicaid and Medicare. Explore your D-SNP options today.',
          canonical:
            'https://www.clearlinkinsurance.com/plans/supplemental-medicare',
          robots: 'follow,index',
        },
        path: '/plans/supplemental-medicare',
        promoCode: '147172',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Explore Medicare D-SNPs | ClearlinkInsurance.com',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-split-content blue-pink-lines-background">
          <div className="wrapper">
            <Billboard
              alignImageToBottom
              mobileAlignImageToBottom
              variant="split"
              image={
                <img
                  src={WomanSmiling}
                  alt="an elderly woman poses and smiles"
                />
              }
              mobileImage={
                <img
                  src={WomanSmilingMobile}
                  alt="an elderly woman poses and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    What is the difference between Medicare and Medicaid when it
                    comes to D-SNPs?
                  </Typography>
                  <Typography variant="body">
                    Medicare is the federal program offering healthcare coverage
                    for U.S. citizens 65 and over. Medicaid comprises state-run
                    healthcare assistance for low-income families and
                    individuals.
                  </Typography>
                  <Typography variant="body">
                    If you are eligible for both Medicare and Medicaid, you can
                    sign up for a Dual: Eligible Special Needs Plan (D-SNP) with
                    a low or $0 monthly premium.
                  </Typography>
                  <LinkButton variant="feature" to="/" color="pink">
                    Browse Plans
                  </LinkButton>
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          customLink={null}
          className="brand-logos header"
          mainContent={
            <Typography variant="h2" style={{ color: '#02168B' }}>
              We partner with these leading insurance brands{' '}
            </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          alignMainContent="left"
          mainContent={
            <>
              <Typography variant="h2">D-SNP benefits and features</Typography>
              <Typography variant="body">
                Dual Eligible Special Needs Plans (D-SNPs) are private-sector
                insurance policies available to those who qualify for Medicare
                (by age or special circumstance) and Medicaid (by income).
                Similarly to Medicare Advantage plans, D-SNPs provide the same
                coverage as Original Medicare (Parts A & B), plus additional
                benefits that differ by plan, location, and provider.
              </Typography>
              <Typography variant="body">
                D-SNPs have numerous benefits to those eligible. First, D-SNPs
                are often available at a low premium cost, with many plans as
                low as $0 monthly. Second, D-SNPs improve care quality and
                efficiency by arranging care on the policyholder’s behalf.
                Third, D-SNPs offer additional health care and wellness benefits
                that fill in the gaps in Original Medicare coverage. 
              </Typography>
              <Typography variant="body">
                D-SNPs may cover the out-of-pocket cost for:
              </Typography>
              <List>
                <ListItem>Dental coverage</ListItem>
                <ListItem>Gym memberships</ListItem>
                <ListItem>Vision coverage</ListItem>
                <ListItem>Hearing coverage</ListItem>
                <ListItem>Transportation assistance</ListItem>
                <ListItem>
                  Stipends for healthy food and over-the-counter medicine
                </ListItem>
                <ListItem>Meal delivery</ListItem>
              </List>
              <Typography>
                If you are eligible, browse plans during a qualifying enrollment
                period to find a D-SNP that matches your specific need.
              </Typography>
            </>
          }
        ></VariableContent>

        <div className="blue-mountains-background hide-nav-button">
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  What is the difference between Medicare and Medicaid?
                </Typography>
                <Typography variant="h4">
                  Both Medicare and Medicaid are government-run programs
                  providing healthcare coverage.
                </Typography>
              </>
            }
            style={{
              color: '#fff',
            }}
          >
            <Stack spacing="l">
              <Columns cardStyled mobileCarousel>
                <Column backgroundColor="primary">
                  <StaticImage
                    src="../../images/illustrations/blue-card.svg"
                    alt="star ratings"
                    layout="fixed"
                    width={100}
                    height={100}
                    className="product-icons"
                  />
                  <Typography variant="h4" className="text-center">
                    Medicare
                  </Typography>
                  <List>
                    <ListItem>
                      is for U.S. citizens 65 or older and people under 65 with
                      certain disabilities or conditions
                    </ListItem>
                    <ListItem>
                      is run by a federal agency called the Centers for Medicare
                      & Medicaid services
                    </ListItem>
                    <ListItem>
                      provides the same coverage at the same cost no matter what
                      state you are in
                    </ListItem>
                    <ListItem>
                      requires some out-of-pocket copayments for some services
                    </ListItem>
                  </List>
                </Column>
                <Column backgroundColor="primary">
                  <StaticImage
                    src="../../images/illustrations/pink-card.svg"
                    alt="star ratings"
                    layout="fixed"
                    width={100}
                    height={100}
                    className="product-icons"
                  />
                  <Typography variant="h4" className="text-center">
                    Medicaid
                  </Typography>
                  <List>
                    <ListItem>
                      follows rules determined by the federal government, but is
                      run by each state
                    </ListItem>
                    <ListItem>
                      is run by a federal agency called the Centers for Medicare
                      & Medicaid services
                    </ListItem>
                    <ListItem>
                      requires little-to-no out-of-pocket payment for most
                      services
                    </ListItem>
                  </List>
                  <Typography variant="body">
                    Dual Eligible Special Needs Plans work alongside Medicaid to
                    increase your healthcare coverage at a low monthly and
                    per-visit cost.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </div>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Who is eligible for a D-SNP?</Typography>
              <Typography variant="h4">
                To qualify for a Dual Eligible Special Needs Plan, you must be
                eligible for coverage from both Medicaid and Medicare.
              </Typography>
            </>
          }
        >
          <Stack spacing="l">
            <Columns cardStyled mobileCarousel>
              <Column backgroundColor="white">
                <Typography variant="h4">Medicaid eligibility</Typography>
                <Typography variant="body">
                  To qualify for Medicaid, you must meet your state’s income
                  requirements. The following Medicaid eligibility categories
                  that qualify you for a D-SNP:
                </Typography>
                <List>
                  <ListItem>Full Medicaid (only)</ListItem>
                  <ListItem>
                    Qualified Medicare Beneficiary without other Medicaid (QMB
                    Only)
                  </ListItem>
                  <ListItem>QMB Plus</ListItem>
                  <ListItem>
                    Specified Low-Income Medicare Beneficiary without other
                    Medicaid (SLMB Only)
                  </ListItem>
                  <ListItem>SLMB Plus</ListItem>
                  <ListItem>Qualifying Individual (QI)</ListItem>
                  <ListItem>
                    Qualified Disabled and Working Individual (QDWI)
                  </ListItem>
                </List>
              </Column>
              <Column backgroundColor="white">
                <Typography variant="h4">Medicare eligibility</Typography>
                <Typography variant="body">
                  U.S. citizens may qualify for Medicare by age or by special
                  qualifying conditions.
                </Typography>
                <List>
                  <ListItem>
                    To qualify by age, you must have lived in the United States
                    for the previous five years and be 65 (or approaching your
                    65th birthday).
                  </ListItem>
                  <ListItem>
                    If you are disabled and have received Social Security
                    benefits for at least 24 consecutive months, you may be
                    eligible for Medicare before age 65.
                  </ListItem>
                  <ListItem>
                    If you suffer from kidney failure, also known as End-Stage
                    Renal Disease (ESRD), and have had a kidney transplant or
                    been on dialysis for three months, you qualify for early
                    benefits.
                  </ListItem>
                  <ListItem>
                    If you have amyotrophic lateral sclerosis (ALS), also known
                    as Lou Gehrig’s Disease, you qualify for early benefits.
                  </ListItem>
                </List>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <SplitContent
          className="reverse-desktop"
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={CoupleOnLaptop}
              alt="an elderly couple looks at laptop while sitting on couch"
            />
          }
          mobileImage={
            <img
              src={CoupleOnLaptopMobile}
              alt="an elderly couple looks at laptop while sitting on couch"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Find a D-SNP in your area today
              </Typography>
              <Typography variant="h4">Find a Medicare D-SNP online</Typography>
              <Typography variant="body">
                Since 2017, our more than 300 U.S.-based and licensed agents
                have helped more than 100,000 people find the right healthcare
                coverage. Working with some of the biggest and most reliable
                Medicare insurance brands, we connect you with the plan that
                works for you.
              </Typography>
              <Typography variant="body">
                If you are eligible for a Dual Eligible Special Needs Plan and
                in a qualifying enrollment period, click or tap the button below
                to browse available plans in your area.
              </Typography>
              <LinkButton variant="" to="/" color="pink">
                Browse Plans
              </LinkButton>
            </>
          }
        />

        <VariableContent
          backgroundColor="light"
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Dual Eligible Special Needs Plans
            </Typography>
          }
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title: 'How do I enroll in a D-SNP?',
                  content: (
                    <>
                      <Typography>
                        If you meet the Medicare and Medicaid eligibility
                        requirements for enrollment and are in a qualifying
                        enrollment period (see question and answer below),
                        enroll in private sector D-SNP coverage by using our
                        plan comparison tool online, or by calling to speak to
                        an agent.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'When do I enroll in a D-SNP?',
                  content: (
                    <>
                      <Typography>
                        If you are eligible for a D-SNP, you can sign up during
                        the Annual Enrollment Period for Medicare, which occurs
                        every year from October 15 through December 7. When you
                        enroll in a policy during this time, it goes into effect
                        on January 1 of the following year.
                      </Typography>
                      <Typography>
                        There are also Special Enrollment Periods for Dual
                        Eligible Special Needs Plans. Since 2019, those who
                        qualify for a D-SNP can use one of these Special
                        Enrollment Periods to change plans once every three
                        months for the first nine months of the year.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'What are the costs to me for a D-SNP?',
                  content: (
                    <>
                      <Typography>
                        Out-of-pocket and premium costs for Dual Eligible
                        Special Needs plans are low. Most plans offer $0 monthly
                        premiums, and low or $0 copays and deductibles. If you
                        receive full Medicaid benefits, those often cover the
                        remainder of the cost.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'What’s the difference between a D-SNP and a C-SNP?',
                  content: (
                    <>
                      <Typography>
                        Medicare has Special Needs Plans outside of the D-SNP.
                        One is the C-SNP or Chronic Condition Special Needs
                        Plan. C-SNPs are only for those with a single chronic
                        condition or related group of chronic conditions that
                        qualify them for the plan. D-SNPs are available to those
                        who qualify for both Medicare and Medicaid.
                      </Typography>
                    </>
                  ),
                },
                {
                  title:
                    'Do I need to pick a primary care physician (PCP) with a D-SNP?',
                  content: (
                    <>
                      <Typography>
                        Yes, D-SNPs do require choosing a primary care physician
                        (PCP). When you enroll in a D-SNP, use our plan
                        comparison tool and search by physician to find a plan
                        that works with your preferred providers.
                      </Typography>
                      <Typography>
                        <strong>Note:</strong> D-SNPs do not require PCP
                        referrals for specialist visits.
                      </Typography>
                    </>
                  ),
                },
                {
                  title:
                    'Can I choose a separate Part D plan with my Medicare Advantage D-SNP?',
                  content: (
                    <>
                      <Typography>
                        Because all Dual Eligible Special Needs Plans are
                        required to provide prescription drug coverage, there is
                        no need to enroll in a separate Medicare Part D
                        prescription drug plan (PDP).
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                <span className="mobile-text">Call Now</span>
                <span className="desktop-text">
                  Call Now to Speak to an Agent {rotatedNumber}
                </span>
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
